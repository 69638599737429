<template>
  <span>
    <v-alert v-if="flaggedReviews.length" class="mt-3 mb-5" border="left" text type="warning" icon="mdi-alert-outline">
      <v-row class="my-0 px-3 pointer" @click="expandAlert = !expandAlert">
        <v-col cols="11" class="pa-0">
          <div class="mb-0 text-body-1"><strong>Please note </strong>flagged reviews:</div>
        </v-col>
        <v-col cols="1" class="pa-0 text-right" align-self="center">
          <v-icon color="warning">{{ expandAlert ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-col>
      </v-row>
      <div v-if="expandAlert">
        <ul class="py-2">
          <li class="warning--text">will not be viewable outside the users dashboard</li>
          <li class="warning--text">cannot be modified or deleted</li>
        </ul>
        until it has been assessed by an admin. Reviews that are reported for valid reasons and verified by an
        admin will be deleted and the user will receive a strike on their account.
      </div>
    </v-alert>
    <v-row class="my-3 mx-0">
      <span class="font-weight-regular text-h6 text-md-h5 text-lg-h5">Notices</span>
    </v-row>
    <v-card elevation="0" outlined class="w-full rounded-lg mb-6">
      <v-data-table :headers="tableHeaders.notices" :items="flaggedReviews" :disable-sort="store.app.onMobile"
                    :dense="store.app.onMobile" :hide-default-header="store.app.onMobile" :hide-default-footer="true">
        <template v-slot:item.reviewId="{ item }">
          <v-col v-if="store.app.onMobile" class="hover pa-2 w-full text-left" style="line-height: 1.2">
            <h3 class="font-weight-medium courseTitle courseCode pointer" @click="notice = { show: true, review: item }">
              Review: {{ item.reviewId }}
              <v-spacer/>
            </h3>
            <p class="text--secondary mb-0 pt-2">
              <v-icon small class="mr-1">mdi-clock-alert-outline</v-icon>Reported on {{ new Date(item.reportedOn).toDateString() }}
            </p>
          </v-col>
          <p v-else class="accent--text text-decoration-underline pointer mb-0 font-weight-medium" @click="notice = { show: true, review: item }">
            Review Reported: {{ item.reviewId }}
          </p>
        </template>
        <template v-slot:item.reportedOn="{ item }">
          {{ new Date(item.reportedOn).toLocaleString() }}
        </template>
      </v-data-table>
    </v-card>
    <v-row class="ma-0 pb-3">
      <span class="font-weight-regular text-h6 text-md-h5 text-lg-h5">My Reviews</span>
    </v-row>
    <ReviewModal @close="editReview.edit = false" @update="reloadData" :course="editReview.course" edit-mode :open-modal="editReview.edit" :rating-data="editReview.review"/>
    <v-card elevation="0" outlined class="relative overflow-y-auto rounded-lg h-full mb-2 w-full">
      <v-data-table :headers="tableHeaders.reviews" :items="reviews" :disable-sort="store.app.onMobile"
                    :dense="store.app.onMobile" :hide-default-header="store.app.onMobile"
                    :hide-default-footer="true" :options="{ itemsPerPage: 50 }">
        <template v-slot:item.course="{ item }">
          <v-col v-if="store.app.onMobile" class="pa-2" style="line-height: 1.2">
            <v-row class="ma-0 w-full align-center">
              <h3 class="font-weight-medium">{{ item.course }}</h3>
              <v-icon color="warning" class="ml-3">mdi-star</v-icon>
              <h3 class="mb-0 mx-1 font-weight-bold">{{ item.rating }}</h3>
              <v-spacer/>
              <v-icon v-if="item.recommend >= 4" color="accent" class="mx-3">mdi-check-circle</v-icon>
              <v-icon v-if="item.flagged" color="error" class="ml-1 mr-3">mdi-flag</v-icon>
              <v-menu transition="slide-y-transition" :offset-y="true" bottom close-on-click nudge-bottom="10" left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="20" class="mobileMenu">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="editUserReview(item)">
                    <v-list-item-content>
                      <p class="align-self-center mb-0 text-no-wrap text-body-2">
                        <v-icon color="accent" size="20" class="mr-2">mdi-note-edit-outline</v-icon>View/edit review
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="removeReview(item)">
                    <v-list-item-content>
                      <p class="align-self-center mb-0 text-no-wrap text-body-2">
                        <v-icon color="error" size="20" class="mr-2">mdi-delete</v-icon>Delete review</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <p class="text--secondary mb-0 pt-2">{{ item.comment }}</p>
            <v-row v-if="item.tags.length" class="ma-0 mt-3 align-center">
              <p class="accent--text font-italic ml-0 mb-0 mr-2 font-weight-medium" v-for="(tag, tagIdx) in item.tags.slice(0, 2)" :key="tagIdx">#{{ tag }}</p>
              <p v-if="item.tags.length > 2" class="accent--text font-weight-medium mb-0">...</p>
            </v-row>
          </v-col>
          <p v-else class="accent--text text-decoration-underline pointer mb-0 font-weight-medium text-body-2"
             @click="$router.push({ path: '/courses', query: { c: item.course } })">{{ item.course }}
          </p>
        </template>
        <template v-slot:item.rating="{ item }">
          <v-rating v-model="item.rating" :readonly="true" background-color="grey" color="warning"
                    hover length="5"/>
        </template>
        <template v-slot:item.recommend="{ item }">
          <v-icon v-if="item.recommend >= 4" color="accent">mdi-check-circle</v-icon>
        </template>
        <template v-slot:item.comment="{ item }">
          <tooltip v-if="item.comment" colour="accent" :max-width="500" icon="mdi-comment-text-outline">
            <span class="font-weight-medium">{{ item.comment }}</span>
          </tooltip>
        </template>
        <template v-slot:item.flagged="{ item }">
          <tooltip v-if="item.flagged" colour="error" icon="mdi-flag">
            <span class="font-weight-medium">Review was reported and is pending assessment</span>
          </tooltip>
        </template>
        <template v-slot:item.tags="{ item }">
          <v-row v-if="item.tags.length" class="ma-0 align-center">
            <p class="font-italic ml-0 mb-0 mr-2" v-for="(tag, tagIdx) in item.tags.slice(0, 3)" :key="tagIdx">#{{ tag }}</p>
            <p v-if="item.tags.length > 3" class="mb-0">...</p>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu transition="slide-y-transition" :offset-y="true" bottom close-on-click nudge-bottom="10" left>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="20" class="mobileMenu">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item @click="editUserReview(item)">
                <v-list-item-content>
                  <p class="align-self-center mb-0 text-no-wrap text-body-2">
                    <v-icon color="accent" size="20" class="mr-2">mdi-note-edit-outline</v-icon>View/edit review
                  </p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="removeReview(item)">
                <v-list-item-content>
                  <p class="align-self-center mb-0 text-no-wrap text-body-2">
                    <v-icon color="error" size="20" class="mr-2">mdi-delete</v-icon>Delete review</p>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="notice.show" max-width="500px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
        <v-card-title class="pt-6 pb-4 text-h5 font-weight-bold accent--text">Flagged Review Details</v-card-title>
        <v-card-text v-if="notice.show" class="pb-0">
          <p class="mb-0 font-weight-bold">Reason</p>
          <p class="mb-2">{{ notice.review.reasonType }}</p>
          <p class="mb-0 font-weight-bold">Reason Explanation</p>
          <p class="mb-2">{{ notice.review.reasonComment || 'n/a' }}</p>
          <p class="mb-0 font-weight-bold">Comment</p>
          <p class="mb-2">{{ notice.review.comment }}</p>
          <p class="mb-0 font-weight-bold">Status</p>
          <p class="mb-2">{{ notice.review.status }}</p>
          <p class="mb-0 font-weight-bold">Reported On</p>
          <p class="mb-2">{{ new Date(notice.review.reportedOn).toLocaleString() }}</p>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer/>
          <v-btn color="accent" text @click="notice.show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>

</template>

<script>
import Tooltip from '@/components/Tooltip.vue'
import ReviewModal from '@/components/review/ReviewModal.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'UserReviews',
  components: { ReviewModal, Tooltip },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    return {
      loading: true,
      reviews: [],
      flaggedReviews: [],
      editReview: {
        edit: false,
        review: {},
        course: { rated: true, code: '', course: '' }
      },
      notice: {
        show: false,
        review: null
      },
      expandAlert: false,
      headers: [
        { text: 'Course', value: 'course' },
        { text: 'Rating', value: 'rating' },
        { text: 'Recommend', sortable: false, value: 'recommend', align: 'center' },
        { text: 'Comment', sortable: false, value: 'comment', align: 'center' },
        { text: 'Tags', sortable: false, value: 'tags' },
        { text: 'Flagged', sortable: false, value: 'flagged', align: 'center' },
        { text: ' ', sortable: false, value: 'actions' }
      ],
      noticeHeaders: [
        { text: 'Subject', value: 'reviewId' },
        { text: 'Reported On', value: 'reportedOn' }
      ],
      mobileHeaders: [0]
    }
  },
  mounted () {
    this.reloadData()
  },
  computed: {
    tableHeaders () {
      if (!this.store.app.onMobile) {
        return {
          notices: this.noticeHeaders,
          reviews: this.headers
        }
      } else {
        return {
          notices: this.noticeHeaders.filter((element, index) => this.mobileHeaders.includes(index)),
          reviews: this.headers.filter((element, index) => this.mobileHeaders.includes(index))
        }
      }
    }
  },
  methods: {
    reloadData () {
      this.getReviews()
      this.getFlaggedReviews()
    },
    async getReviews () {
      const q = {
        query: 'query getUserReviews { getUserReviews { reviews } }',
        operationName: 'getUserReviews'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.reviews = graphQlRes.data.getUserReviews.reviews
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getFlaggedReviews () {
      const q = {
        query: 'query getFlaggedReviews { getFlaggedReviews { ' +
          'reviewId, reasonType, reasonComment, comment, status, reportedOn ' +
          '} }',
        operationName: 'getFlaggedReviews'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.flaggedReviews = graphQlRes.data.getFlaggedReviews
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async editUserReview (review) {
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { code, course, bird, drop, rating, numReviews } }',
        variables: { course: review.course },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data && graphQlRes.data.courseInfo.length) {
            this.editReview.course = graphQlRes.data.courseInfo[0]
            this.editReview.review = { ...review }
            this.editReview.edit = true
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async removeReview (review) {
      const q = {
        query: 'mutation removeReview ($category: String!, $value: String!) { removeReview (category: $category, value: $value) { status, message } }',
        variables: { category: 'course', value: review.course },
        operationName: 'removeReview'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.removeReview.status) {
              this.$toast.info(graphQlRes.data.removeReview.message)
              this.getReviews()
            } else {
              this.$toast.warning(graphQlRes.data.removeReview.message)
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    }
  }
}
</script>

<style scoped>
  .v-chip--disabled {
    opacity: 1;
  }
  .theme--dark.v-data-table {
    background-color: var(--background-colour) !important;
  }
  >>>.v-data-table__mobile-row__cell {
    min-width: 100% !important;
    text-align: left;
  }
</style>
